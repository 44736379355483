<template>
  <v-dialog
    v-model="dialog"
    max-width="375"
    :persistent="load"
  >
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="body-1 pt-4">
        <p
          v-html="text"
          class="text-left"
        ></p>
        <v-form ref="form" class="ma-4" @submit.prevent="admin ? cambiar_clave_usuario() : cambiar_clave()">
          <v-text-field
            v-if="!(expired || admin)"
            v-model.trim="pass_old"
            :append-icon="show_pass_old ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_old ? 'text' : 'password'"
            :autofocus="!(expired || admin)"
            label="Clave actual"
            tabindex="1"
            @click:append="show_pass_old = !show_pass_old"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass"
            label="Clave nueva"
            hint="Mínimo 6 caracteres"
            tabindex="1"
            :rules="[rules.min, rules.pass_diff]"
            :append-icon="show_pass ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass ? 'text' : 'password'"
            :autofocus="expired || admin"
            validate-on-blur
            counter
            @click:append="show_pass = !show_pass"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass_repeat"
            label="Repita su clave nueva"
            tabindex="1"
            :rules="[rules.pass_confirm]"
            :append-icon="show_pass_repeat ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_repeat ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass_repeat = !show_pass_repeat"
          ></v-text-field>
          <v-btn
            color="primary"
            class="mt-8"
            type="submit"
            tabindex="1"
            :loading="load"
            block
          >
            Confirmar
          </v-btn>
        </v-form>
        <v-expand-transition>
          <v-alert
            v-if="error"
            type="error"
            dense
          >
            {{ message }}
          </v-alert>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEasyJwt } from "vue-easy-jwt"
import { Decrypt } from "../util/aes"

export default {
  data () {
    return {
      load: false,
      error: false,
      show_pass: false,
      show_pass_old: false,
      show_pass_repeat: false,
      message: '',
      pass: '',
      pass_old: '',
      pass_repeat: '',
      rules: {
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass_diff: value => {
          if (!this.admin) {
            return value !== this.pass_old || 'Ingrese una clave diferente a la anterior' 
          }
          else {
            return true
          }
        },
        pass_confirm: value => value === this.pass || 'Las claves no coinciden',
      },
    }
  },
  props: {
    value: Boolean,
    title: String,
    text: String,
    user: String,
    password: {
      type: String,
      default: '',
    },
    expired: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    estado: {
      type: String,
      default: '',
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.expired) {
          this.pass_old = this.password
        }
      } else {
        this.clear()
      }
    },
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    nuevo_estado: {
      get () {
        return this.estado
      },
      set (value) {
        this.$emit('update:estado', value)
      }
    }
  },
  methods: {
    async cambiar_clave () {
      this.clear_message()
      if (this.$refs.form.validate()) {
        this.load = true
        let route = 'actualizarClave'

        if (!this.expired) {
          route = 'cambiarClave'
          const jwt = new VueEasyJwt()

          if (this.pass_old !== Decrypt(jwt.decodeToken(this.$store.state.token).credenciales.clave)) {
            this.message = 'Clave incorrecta'
            this.error = true
            this.load = false
            return
          }
        }

        await this.$store.dispatch('cambiar_clave', {
          user: this.user,
          password: this.pass,
          route: route
        })
          .then(async (data) => {

            await this.$store.dispatch('login', {
              user: this.user,
              password: this.pass,
            })
              .then(async () => {

                if (this.expired) {
                  await this.$store.dispatch('menu')
                    .then(() => {
                      this.$router.push({ path: "/" })
                    })
                    .catch(error => {
                      this.message = error.message
                      this.error = true
                    })
                } else {
                  this.dialog = false
                  this.$store.dispatch('show_snackbar', {
                    text: data.message,
                    color: 'success',
                  })
                }

              })
              .catch(error => {
                this.message = error.message
                this.error = true
              })

          })
          .catch(error => {
            this.message = error.message
            this.error = true
          })

        this.load = false
      }
    },
    async cambiar_clave_usuario () {
      this.clear_message()
      if (this.$refs.form.validate()) {
        this.load = true
        await this.$store.dispatch('cambiar_clave', {
            user: this.user,
            password: this.pass,
            route: 'actualizarClave'
          })
            .then(async (data) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: data.message,
                color: 'success',
              })

              // actualiza el estado en la vista
              await this.$store.dispatch('get_user_estado', this.user)
                .then((data) => {
                  this.nuevo_estado = data
                  this.$emit('action')
                })
            })
            .catch(error => {
              this.message = error.message
              this.error = true
            })
        this.load = false
      }
    },
    clear () {
      this.error = false
      this.show_pass = false
      this.show_pass_old = false
      this.show_pass_repeat = false
      this.pass = ''
      this.pass_old = ''
      this.pass_repeat = ''
      this.$refs.form.resetValidation()
    },
    clear_message () {
      this.message = ''
      this.error = false
    },
  }
}
</script>