<template>
  <v-dialog
    v-model="dialog"
    max-width="375"
    :persistent="load_buscar || load_eliminar"
  >
    <v-card>
      <v-card-title>
        Cambiar imagen de perfil
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="text-center">
        <v-avatar
          size="150"
          class="my-8"
        >
          <v-img
            :src="$store.state.img_perfil === '' ? require('../assets/bb.webp') : `data:image/jpeg;base64,${$store.state.img_perfil}`"
          ></v-img>
        </v-avatar>
        <v-row class="pa-4">
          <v-col cols="12" sm="6">
            <BtnConfirmar
              color="error"
              nombre="Eliminar"
              icono="fas fa-trash"
              texto="¿Desea eliminar su foto de perfil actual?"
              :disabled="load_buscar || $store.state.img_perfil === ''"
              :loading="load_eliminar"
              :block="true"
              @action="eliminar"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              :disabled="load_eliminar"
              :loading="load_buscar"
              block
              @click="buscar_foto"
            >
              <v-icon left>fas fa-camera</v-icon>
              Cambiar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog_archivo"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          Seleccionar imagen
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_archivo = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-0">
          <FileDropzone
            :key="refrescar"
            :multiple="false"
            :tipos="['.jpg', '.png', '.webp']"
            :max_size="1"
            :ruta="ruta_carpeta"
            :nombre="$store.state.username"
            :alerta="false"
            :accion="true"
            @action="cambiar_imagen"
            :raiz="1"
            :tabla="1"
            :id="1"
          />
          <div class="text-right">
            <v-btn
              color="error"
              class="mt-n7 mr-7"
              @click="dialog_archivo = false"
            >
              Cancelar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import FileDropzone from './util/FileDropzone'
import BtnConfirmar from './util/BtnConfirmar'

export default {
  data () {
    return {
      refrescar: 0,
      load_buscar: false,
      load_eliminar: false,
      dialog_archivo: false,
      foto_vieja: '',
      ruta_carpeta: '/imagenes_perfil/' + this.$store.state.username,
    }
  },
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  components: {
    FileDropzone,
    BtnConfirmar,
  },
  watch: {
    dialog_archivo (val) {
      if (val) {
        (this.refrescar += 1)
      }
    },
  },
  methods: {
    async buscar_foto () {
      this.load_buscar = true

      await this.$store.dispatch('get_all_files', { path: this.ruta_carpeta, raiz: 1 })
        .then(res => {
          // guarda el nombre de la foto vieja
          if (res.size > 0) {
            this.foto_vieja = res.files[0].name
          }
        })
        .catch(() => {
          // si tira error es porque la carpeta no existe, por lo tanto no hay fotos previas
        })

      this.dialog_archivo = true
      this.load_buscar = false
    },
    async eliminar () {
      this.load_eliminar = true

      await this.$store.dispatch('get_all_files', { path: this.ruta_carpeta, raiz: 1 })
        .then(async (res) => {
          
          // si encontro la foto, la borra
          if (res.size > 0) {
            const nombre = res.files[0].name

            await this.$store.dispatch('delete_file', { path: this.ruta_carpeta + '/' + nombre, raiz: 1, id: 1, tabla: 1 })
              .then(async () => {

                // foto borrada, actualzia la imagen y notifica
                await this.$store.dispatch('get_img_perfil')

                this.$store.dispatch('show_snackbar', {
                  text: 'Imagen de perfil eliminada correctamente',
                  color: 'success',
                })

              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          }

        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })

      this.load_eliminar = false
    },
    async cambiar_imagen (res) {

      // si foto vieja es un string vacio significa que es la primera vez que sube una foto de perfil
      if (this.foto_vieja !== '') {

        // si foto vieja es igual a la foto que subio significa que subio una del mismo tipo de archivo y se sobreescribio
        if (this.foto_vieja !== res.name) {

          // en caso que sean distintas significa que subio una de otro tipo y se debe borrar la foto vieja
          await this.$store.dispatch('delete_file', { path: this.ruta_carpeta + '/' + this.foto_vieja, raiz: 1, id: 1, tabla: 1 })
            .then(() => {
              // foto vieja borrada
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        }
      }

      // actualiza la foto de perfil y notifica
      await this.$store.dispatch('get_img_perfil')
      this.dialog_archivo = false
      this.$swal.fire({
        icon: 'success',
        title: `Imagen de perfil actualizada correctamente`
      })
      
    }
  }
}
</script>