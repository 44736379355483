import config from '../../config'
import store from '../store'

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getNotaCredito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ncPeticion = await fetch(`${config.BASE_URL}/adminAfip/getNotaCredito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nc = await ncPeticion.json()
          nc.msj = nc.message
          return resolve(nc)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getNotaCredito: ' + error.message
          })
        }
      })
    },
    async getFactura({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let fcPeticion = await fetch(`${config.BASE_URL}/adminAfip/getFactura`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let fc = await fcPeticion.json()
          fc.msj = fc.message
          return resolve(fc)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getFactura: ' + error.message
          })
        }
      })
    },
    async permiteFacturar ({}, datos) {
      return await new Promise(async (resolve, reject) => {
        try {
          let fcPeticion = await fetch(`${config.BASE_URL}/adminAfip/permiteFacturar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let fc = await fcPeticion.json()
          fc.msj = fc.message
          return resolve(fc)
        } catch(error) {
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getFactura: ' + error.message
          })
        }
      })
    }
  }
}