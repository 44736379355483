<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :class="clase"
        :color="color"
        :disabled="disabled"
        :loading="loading"
        :icon="icon_button"
        :x-small="small"
        :title="title"
        :block="block"
        :dark="dark"
      >
        <v-icon v-if="!sin_icono" :small="small" :left="!icon_button">{{ icono }}</v-icon>
        <template v-if="!icon_button">
          {{ nombre }}
        </template>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ titulo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <span v-html="texto"></span>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="success"
          class="mr-2"
          @click="confirmar"
        >
          Sí
        </v-btn>
        <v-btn
          color="error"
          @click="dialog = false"
        >
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Componente botón que muestra un modal para confirmar una accion,
 *  la funcion que realiza en caso de que seleccione el boton 'Sí'
 *  se define en el padre mediante: @action="funcion"
 */
export default {
  data () {
    return {
      dialog: false,
    }
  },
  props: {
    // si se activa, el boton pasa a ser de tipo icon y no muestra el nombre
    icon_button: {
      type: Boolean,
      default: false,
    },
    // crea el boton con nombre pero sin  icono
    sin_icono: {
      type: Boolean,
      default: false,
    },
    // nombre del boton
    nombre: {
      type: String,
      default: 'Guardar',
    },
    // icono del boton
    icono: {
      type: String,
      default: 'fas fa-save',
    },
    // color del boton
    color: {
      type: String,
      default: 'success',
    },
    // titulo del modal
    titulo: {
      type: String,
      default: 'Confirmar',
    },
    // texto del modal
    texto: {
      type: String,
      default: '¿Desea guardar los cambios realizados?',
    },
    // etiqueta class="" del boton
    clase: {
      type: String,
      default: '',
    },

    // propiedades personalizables para el boton
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmar () {
      this.$emit('action')
      this.dialog = false
    }
  }
}
</script>